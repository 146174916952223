import { Injectable, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationStart, RoutesRecognized  } from '@angular/router';
import { Subscription } from "rxjs";
import { map } from 'rxjs/operators'; //, filter, scan
import { HttpService } from '../http.service';
import { trigger, state, animate, style, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers: [HttpService],
  animations: [
    trigger('navAnimation',[
      state('standard', style({
        opacity: 1
      })),
      transition('standard => void', animate(100)),

      transition('void => standard', [
        style({
          opacity: 0
        }),
        animate(400)
      ]),
    ]),
  ]
})

@Injectable()
export class NavigationComponent implements OnInit, OnDestroy {

  routetargetprev: string[];
  routetargetnext: any[];
  nextpath: string;
  lastpath: string;
  navAnimationState = 'standard';
  show = false;
  private nextPage;
  private lastPage;

  private subscription: Subscription;
  id: string;

  constructor(private router: Router,private activatedRoute: ActivatedRoute, private httpService: HttpService ) { }

  allPages = this.httpService.getPages();

  ngOnInit(): void {
      this.subscription = this.router.events.subscribe(val => {

          if (val instanceof RoutesRecognized) {

              this.id = val.state.root.firstChild.params.id;
              this.nextpath = '/page';

              if(this.id) {

                this.subscription = this.httpService.getNextPrevArticle(this.id).subscribe( data => {

            //    console.log(data[0].sort +' '+ data[1].sort);

                  if(this.id === '1') {
                    this.routetargetprev = [''];
                    this.routetargetnext = ['/page',data[0].sort];
                  } else if ( data[1] ) {
                    this.routetargetprev = ['/page',data[0].sort];
                    this.routetargetnext = ['/page',data[1].sort];
                  } else {
                    this.routetargetprev = ['/page',data[0].sort];
                    this.routetargetnext = ['/page',this.id];
                  }

              });

            } else {
              this.routetargetprev = ['/'];
              this.routetargetnext = ['/page',1];
            }

          }
      });

  }

  onShow() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
