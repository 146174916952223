import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { PageComponent } from './page/page.component';
import { CoverComponent } from "./page/cover.component";

const APP_ROUTES: Routes = [
//	{ path: '', component: HomeComponent, data: { state: 'home' }  },
	{ path: '', component: CoverComponent, data: { state: 'cover' }  },
	{ path: 'page/:id', component: PageComponent, data: { state: 'page' }  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
