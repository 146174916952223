import { Component } from '@angular/core';
import { HttpService } from './http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HttpService]
})
export class HomeComponent {

  allMagazines = this.httpService.getMagazines();
  
  constructor(private httpService: HttpService) { }

}
