import { Injectable, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from "rxjs";
import { map } from 'rxjs/operators'; //, filter, scan
import { HttpService } from '../http.service';
import { trigger, state, animate, style, transition, keyframes } from '@angular/animations';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  providers: [HttpService],
  animations: [
    trigger('title',[
      transition('void => *', [
        style({
          transform: 'translateX(-150px)',
          opacity: 0
        }),
        animate(700, style({
          transform: 'translateX(0)',
          opacity: 1
        })),
      ]),
    ]),
    trigger('wrapper', [
      transition('void => *', animate(1800, keyframes([
        style({
          transform: 'translateX(-150px)',
          opacity: 0,
          offset:0
        }),
        style({
          transform: 'translateX(-150px)',
          opacity: 0,
          offset:0.6
        }),
        style({
          transform: 'translateX(0)',
          opacity: 1,
          offset:1
        })
      ]))),
    ])
  ]
})

@Injectable()
export class PageComponent implements OnInit, OnDestroy {

  contacts: object[];
  private subscription: Subscription;
  id: string;
  pageTitle: string;
  pageSum;
  pagesArticles: object;

  allArticles;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private httpService: HttpService ) {}

  ngOnInit() {

    // total amount of pages
  //  this.pageSum = Object.keys(this.getPagesArticlesData()).length;
//    console.log(this.pageSum);

  //  console.log(Object.keys(this.pageSum).length);

    this.subscription = this.activatedRoute.params.subscribe(
      (paramas: Params) => {
        this.id = paramas['id']

        // get the page infos and set the title
        this.getPageData();
        this.getPagesArticlesData();

        //console.log(this.pageSum);

      }
    )

  }

  // get id of the page
  getPages () {
    return this.httpService.getPages().pipe(map(response => response ));;
  }

  getPagesArticlesData () {
    this.pagesArticles = this.httpService.getPagesWithArticles(this.id);
    this.pageSum = Object.keys(this.pagesArticles).length;

    console.log(this.pageSum);
  }

  // get title of the page
  getPageData () {
    this.subscription = this.httpService.getPage(this.id).subscribe( data => {
        this.pageTitle = data[0].title;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
