import { Component } from '@angular/core';

import { NavigationComponent } from './navigation/navigation.component';
import { PageComponent } from './page/page.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {


}
