import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.css'],
  providers: [HttpService]
})
export class CoverComponent implements OnInit {

  constructor(private httpService: HttpService ) {}

  coverContents = this.httpService.getCoverContent();

  ngOnInit() {

  }

}
