import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from  '@angular/common/http';


import { AppComponent } from './app.component';
import { PageComponent } from './page/page.component';
import { HomeComponent } from './home.component';

import {routing} from './app.routing';
import { NavigationComponent } from './navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoverComponent } from './page/cover.component';

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    HomeComponent,
    NavigationComponent,
    CoverComponent,
  ], 
  imports: [
    BrowserModule,
	  HttpClientModule,
	  routing,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
