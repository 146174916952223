import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs'; // Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent
import { map } from 'rxjs/operators'; //, filter, scan



@Injectable()
export class HttpService {

  constructor(private http: HttpClient) {}

  public pageData;

  getMagazines() {
	  return this.http.get('https://dynamicmagazine.simadesign.de/api/magazines');
  }

  getPages() {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/pages');
  }

  getPage( pid: string) {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/page/'+pid); //,{params: params});
  }

  getArticles( pid: string) {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/articles/'+pid);
  }

  getPagesWithArticles( pid: string) {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/pagearticles/'+pid);
  }

  getNextPrevArticle( pid: string) {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/nextprevarticles/'+pid);
  }

  getCoverContent() {
    return this.http.get('https://dynamicmagazine.simadesign.de/api/covercontent');
  }

}
